import React from "react"
import { Layout, Section, TextContainer, Hero } from "../components"

import * as styles from "../styles/about.module.scss"

export default function About() {
  return (
    <Layout theme="dark">
      <Hero
        className={styles.aboutHero}
        theme="dark"
        image={{ src: "about-bg1.png", alt: "about-bg" }}
        hero={{
          title: "Professionalism guaranteed",
          text:
            "With 23 years of history, S B Tan Audit PAC guarantees quality services at your convenience. Our group of qualified and experienced accountants adopt best practices that we have adapted over the years.",
        }}
      ></Hero>
      <Section className={styles.aboutSection}></Section>
    </Layout>
  )
}
